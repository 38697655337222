<template>
  <div class="contents dashboard" v-loading="loading">
    <div class="title flexB">
      <h2>대시보드</h2>
    </div>
    <div class="box one filter">
      <div class="top">
        <!-- <p>오늘 하루 방문자 수 40명</p> -->
        <div class="buttonWrap refresh">
          <p>{{ moment().format("YYYY.MM.DD HH:mm") }}</p>
          <button
            class="basic"
            @click="getCount"
            :class="{ loading: inactive == true }"
          >
            새로고침
            <img src="@/assets/images/refresh.svg" alt="새로고침" />
          </button>

          <!-- <el-button class="basic" @click="getCount" :loading="inactive">새로고침</el-button> -->
        </div>
      </div>

      <div class="mainBox">
        <div class="contBox">
          <h2>가입자 수</h2>
          <div class="contWrap first flex">
            <div class="cont">
              <h4>전체 가입자</h4>
              <h3>{{ dashboard[0] }}<span>명</span></h3>
            </div>

            <div class="cont">
              <h4>월별 가입자</h4>
              <h3>{{ dashboard[2] }}<span>명</span></h3>
            </div>
            <div class="cont">
              <h4>일별 가입자</h4>
              <h3>{{ dashboard[1] }}<span>명</span></h3>
            </div>
            <div class="cont">
              <h4>탈퇴 회원</h4>
              <h3>{{ dashboard[3] }}<span>명</span></h3>
            </div>
          </div>
        </div>
        <div class="contBox">
          <h2>상담요청 수</h2>
          <div class="contWrap second flex">
            <div class="cont">
              <h4>전체 상담 요청 수</h4>
              <h3>{{ dashboard[4] }}<span>개</span></h3>
            </div>
            <div class="cont">
              <h4>이번달 상담 요청 수</h4>
              <h3>{{ dashboard[5] }}<span>개</span></h3>
            </div>
            <div class="cont">
              <h4>신규 상담 요청 수</h4>
              <h3>{{ dashboard[6] }}<span>개</span></h3>
            </div>
          </div>
        </div>

        <div class="contBox">
          <h2>문의 수</h2>
          <div class="contWrap third flex">
            <div class="cont">
              <h4>전체 문의 수</h4>
              <h3>{{ dashboard[7] }}<span>개</span></h3>
            </div>
            <div class="cont">
              <h4>이번달 문의 수</h4>
              <h3>{{ dashboard[8] }}<span>개</span></h3>
            </div>
            <div class="cont">
              <h4>신규 문의 수</h4>
              <h3>{{ dashboard[9] }}<span>개</span></h3>
            </div>
          </div>
        </div>
        <div class="contBox">
          <h2>리뷰 수</h2>
          <div class="contWrap third flex">
            <div class="cont">
              <h4>전체 리뷰 수</h4>
              <h3>{{ dashboard[10] }}<span>개</span></h3>
            </div>
            <div class="cont">
              <h4>이번달 리뷰 수</h4>
              <h3>{{ dashboard[11] }}<span>개</span></h3>
            </div>
            <div class="cont">
              <h4>신규 리뷰 수</h4>
              <h3>{{ dashboard[12] }}<span>개</span></h3>
            </div>
          </div>
        </div>
        <div class="contBox">
          <h2>웨딩 패키지 계약 수</h2>
          <div class="contWrap third flex">
            <div class="cont">
              <h4>총 계약 건수</h4>
              <h3>{{ dashboard[13] }}<span>개</span></h3>
            </div>
            <div class="cont">
              <h4>당월 패키지 계약 수</h4>
              <h3>{{ dashboard[14] }}<span>개</span></h3>
            </div>
            <div class="cont">
              <h4>신규 패키지 계약 수</h4>
              <h3>{{ dashboard[15] }}<span>개</span></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { format } from "@/mixins/format";
import { fetchDashCount } from "@/api/admin";

export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      userList: [],
      total: 0,
      currentPage: 1,
      dashboard: [],
      loading: false,
      inactive: false,
      activeName: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "0");
  },
  mounted() {
    this.loading = true;
    this.getCount();
  },
  methods: {
    getCount() {
      this.inactive = true;

      fetchDashCount().then((res) => {
        if (res.data.status == 200) {
          this.dashboard = res.data.data;
          this.loading = false;
          this.inactive = false;
        }
      });
    },
  },
};
</script>
